<template>
  <div>
    <defaulttop active="1"></defaulttop>

    <!-- Banner -->
    <div class="banner">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <img :src="item.img" class="bannerimg" @click="bannerLink(item.path)" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <div class="flex flex-column flex-center box">
      <div class="flex flex-column box1">
        <div class="flex flex-center">
          <img src="../assets/icon1.png" alt="" class="icon1" />
        </div>
        <p class="title">重庆大数据人工智能创新中心</p>
        <p class="titles">CICBA</p>
        <p class="info">
          重庆大数据人工智能创新中心由成都大数据产业技术研究院与重庆渝隆集团，成渝两地国资联合创建。中心旨在打造成渝双城地区经济圈大数据人工智能创新生态，推动大数据智能化产业落地与应用实践。中心定位于聚焦大数据智能化领域的新型孵化平台，以数据治理为内核，以智慧城市为外延，以投资孵化为纽带，以市场开拓为导向，着力建设“一中心、一基金、一管道、一集群”，形成大数据智能化应用创新企业集群与产业生态。
        </p>
        <div class="flex flex-row flex-centers itembox">
          <div :class="[
              'flex flex-column flex-center item',
              itemindex == 0 ? 'itemac' : '',
            ]" @click="itemclick(0)">
            <p>业务方向</p>
          </div>
          <div :class="[
              'flex flex-column flex-center item',
              itemindex == 1 ? 'itemac' : '',
            ]" @click="itemclick(1)">
            <p>业务矩阵</p>
          </div>
        </div>
        <div class="iteminfobox" v-if="itemindex == 0">
          <div class="flex flex-space flex-centers">
            <div class="flex flex-column iteminfo bdr1">
              <div class="flex flex-row flex-centers">
                <img src="../assets/gkj.png" class="icon2" />
                <p class="iteminfotitle">高新技术转化</p>
              </div>
              <p class="iteminfotxt">
                将创新中心高新研究技术成果生产化、作用化、商业化，运用到实际的生产生活中
              </p>
            </div>
            <div class="flex flex-column iteminfo bdr1">
              <div class="flex flex-row flex-centers">
                <img src="../assets/cy.png" class="icon2" />
                <p class="iteminfotitle">产业孵化</p>
              </div>
              <p class="iteminfotxt">
                通过创新中心集中、有效的资源整合优势，建立通往政府、产业、高校及研究机构等的广泛渠道和接口，组织和搭建现代企业产业孵化网络
              </p>
            </div>
            <div class="flex flex-column iteminfo">
              <div class="flex flex-row flex-centers">
                <img src="../assets/cj.png" class="icon3" />
                <p class="iteminfotitle">创新场景打造</p>
              </div>
              <p class="iteminfotxt">
                吸纳行业领先的创新成果，打造创新应用场景，营造新生态，发展新经济，培育新动能
              </p>
            </div>
          </div>
          <div class="flex flex-center">
            <p class="line flex flex-box"></p>
          </div>
          <div class="flex flex-space flex-centers">
            <div class="flex flex-column iteminfo bdr1">
              <div class="flex flex-row flex-centers">
                <img src="../assets/dsj.png" class="icon2" />
                <p class="iteminfotitle">大数据智库</p>
              </div>
              <p class="iteminfotxt">
                以前沿科技为特征建设大数据智库，利用大数据、人工智能、云计算及前沿感知分析技术，消除政府、企业、公众间的信息差、文化差、知识差
              </p>
            </div>
            <div class="flex flex-column iteminfo bdr1">
              <div class="flex flex-row flex-centers">
                <img src="../assets/jis.png" class="icon2" />
                <p class="iteminfotitle">关键技术研发</p>
              </div>
              <p class="iteminfotxt">
                加强前沿基础理论研究及关键性技术攻关支持力度，如计算机视觉理论、机器学习、人机交互、环境感知等，提升技术壁垒
              </p>
            </div>
            <div class="flex flex-column iteminfo"></div>
          </div>
        </div>
        <div class="iteminfobox" v-if="itemindex == 1">
          <div class="flex flex-space flex-centers">
            <div class="flex flex-row flex-box flex-centers iteminfo bdr1">
              <img src="../assets/icon2.png" class="icon4" />
              <div class="flex flex-column">
                <p class="iteminfotitle">一中心</p>
                <p class="iteminfotxt">建立大数据技术研究中心</p>
              </div>
            </div>
            <div class="flex flex-row flex-box flex-centers iteminfo">
              <img src="../assets/icon3.png" class="icon5" />
              <div class="flex flex-column">
                <p class="iteminfotitle">一管道</p>
                <p class="iteminfotxt">大数据人才流动管道</p>
              </div>
            </div>
          </div>
          <div class="flex flex-center">
            <p class="line flex flex-box"></p>
          </div>
          <div class="flex flex-space flex-centers">
            <div class="flex flex-row flex-box flex-centers iteminfo bdr1">
              <img src="../assets/icon4.png" class="icon6" />
              <div class="flex flex-column">
                <p class="iteminfotitle">一基金</p>
                <p class="iteminfotxt">大数据创业企业孵化基金</p>
              </div>
            </div>
            <div class="flex flex-row flex-box flex-centers iteminfo">
              <img src="../assets/icon5.png" class="icon7" />
              <div class="flex flex-column">
                <p class="iteminfotitle">一集群</p>
                <p class="iteminfotxt mx">培育重庆大数据智能产业</p>
                <p class="iteminfotxts mx">新总部集群</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box2 flex flex-center">
      <div class="flex flex-space flex-centers box2-box">
        <template v-for="(item, index) in countNum">
          <p class="lines" :key="'lines_' + index"></p>
          <div class="flex flex-column flex-center flex-box" :key="'txt_' + index">
            <p class="box2title" v-text="item.subtitle"></p>
            <div class="flex flex-end-align">
              <p class="box2num" v-text="item.value"></p>
              <p class="box2lag" v-text="item.label"></p>
            </div>
          </div>
        </template>
        <p class="lines"></p>
      </div>
    </div>
    <div class="flex flex-column flex-center box bggradient">
      <div class="flex flex-column box1">
        <div class="flex flex-center">
          <img src="../assets/icon1.png" alt="" class="icon1" />
        </div>
        <p class="title">多场景多领域解决方案</p>
        <div class="flex flex-center titles pore">
          <p>Problem solving</p>
          <div class="flex flex-row flex-centers more hovera" @click="toMore('/services')">
            <p>查看更多</p>
            <img src="../assets/fh.png" class="fhicon" />
          </div>
        </div>
        <div class="flex flex-space flex-centers mt96">
          <div :class="problemCls(2)" @click="swipernav(2)">
            <img src="../assets/csjc.png" class="box2icon" />
            <p>城市基础设施</p>
          </div>
          <div :class="problemCls(3)" @click="swipernav(3)">
            <img src="../assets/suz.png" class="box2icon" />
            <p>政企数字</p>
          </div>
          <div :class="problemCls(4)" @click="swipernav(4)">
            <img src="../assets/shujuz.png" class="box2icon" />
            <p>数据治理和数字孪生</p>
          </div>
          <div :class="problemCls(0)" @click="swipernav(0)">
            <img src="../assets/gogngong.png" class="box2icon" />
            <p>公共应急</p>
          </div>
          <div :class="problemCls(1)" @click="swipernav(1)">
            <img src="../assets/nongye.png" class="box2icon" />
            <p>三农行业</p>
          </div>
        </div>
      </div>
      <div class="linea"></div>
      <div class="flex flex-column box1ss">
        <div class="banner">
          <swiper :options="swiperOption1" ref="swiper1">
            <swiper-slide>
              <div class="flex flex-column flex-center swiperOption1item">
                <p class="swiperOption1itemtitle">城市基础设施解决方案</p>
                <p style="line-height: 26px; font-size: 16px; color: #9da3ab">
                  城市基础设施管理缺口仍较大，需进一步提升城市基础设施“数智化”水平，实现技术和治理升级，提升城市韧性和治理效能
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="flex flex-column flex-center swiperOption1item">
                <p class="swiperOption1itemtitle">政企数字解决方案</p>
                <p style="line-height: 26px; font-size: 16px; color: #9da3ab">
                  政企数字解决方案面向政企客户提供“数据+应用场景”的服务模式，利用人工智能、大数据技术赋能政企服务领域，实现数据从生产、整合、管理、应用和驱动一体化的支撑，用数据提升政务服务水平及监管效能、促进产业降本增效及转型升级、增强民生服务的便捷
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="flex flex-column flex-center swiperOption1item">
                <p class="swiperOption1itemtitle">数据治理和数字孪生解决方案</p>
                <p style="line-height: 26px; font-size: 16px; color: #9da3ab">
                  数据治理与数字孪生解决方案目的在于通过大数据前沿技术，高效地应对现代产业发展、园区治理的复杂性与不确定性，增强园区及地区的信息化水平，带动更高的地区生产效率
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="flex flex-column flex-center swiperOption1item">
                <p class="swiperOption1itemtitle">公共应急解决方案</p>
                <p style="line-height: 26px; font-size: 16px; color: #9da3ab">
                  针对疫情等急性公共事件，创新中心可提供数智应急指挥系统和电子封条监控管理应用，以集中化的数据展示大屏协调指挥每个阶段的应急事务处置管理工作，通过电子封条实时管控危险群体，保证整体环境有序可控
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="flex flex-column flex-center swiperOption1item">
                <p class="swiperOption1itemtitle">三农行业解决方案</p>
                <p style="line-height: 26px; font-size: 16px; color: #9da3ab">
                  三农问题主要体现为农业数字化应用范围不广、农村信息基础设施建设滞后、农民使用信息终端水平相对较弱等。“三农行业解决方案”以大数据为抓手，协调推进农业生产信息化、农业经营智慧化、农业农村管理高效化、农业农村服务便捷化，建设集大数据中心、三农综合服务平台、应用服务为一体的三农大数据
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="flex flex-column flex-center box bggradient bg4">
      <div class="flex flex-column box1">
        <div class="flex flex-center">
          <img src="../assets/icon1.png" alt="" class="icon1" />
        </div>
        <p class="title">案例分享</p>
        <div class="flex flex-center titles pore">
          <p>Company case</p>
          <div class="flex flex-row flex-centers more hovera" @click="toMore('/innovation')">
            <p>查看更多</p>
            <img src="../assets/fh.png" class="fhicon" />
          </div>
        </div>
      </div>
      <div class="flex flex-space box1" style="margin-left: 25px">
        <el-carousel :interval="4000" style="width: 100%" trigger="click" type="card" @click.native="toMore2">
          <el-carousel-item v-for="item in caseList" :key="item.title">
            <div class="flex flex-column flex-center swiperOption1items">
              <p class="swiperOption1itemtitle">{{ item.title }}</p>
              <p class="lsdkj" style="overflow: hidden" v-html="item.summary"></p>
              <p class="flex flex-center seedetail" @click.stop="
                  toMore('/innovation', { id: item.id, pid: 2, classid: 14 })
                " style="cursor: pointer">
                查看详情
              </p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="flex flex-column flex-center bgbox1" v-if="ciarticleListTop4.length > 0">
      <div class="flex flex-column box1">
        <div class="flex flex-center">
          <img src="../assets/icon1.png" alt="" class="icon1" />
        </div>
        <p class="title">中心动态</p>
        <div class="flex flex-center titles pore">
          <p>Center dynamic</p>
          <div class="flex flex-row flex-centers more hovera" @click="toMore('/centralnews')">
            <p>查看更多</p>
            <img src="../assets/fh.png" class="fhicon" />
          </div>
        </div>
      </div>
      <div class="contextbox">
        <div class="flex" v-for="(item, index) in ciarticleListTop4" :key="index" @click="btndetails(item)">
          <div class="context2">
            <div></div>
          </div>
          <div class="context1">
            <div class="flex flex-centers">
              <div class="flex flex-centers">
                <div class="txt1 hovera" v-text="
                    (item.className ? `【${item.className}】` : ``) + item.title
                  "></div>
                <div class="isnew">NEW</div>
              </div>
              <div class="times">{{ item.createTime }}</div>
            </div>
            <div class="txt2 hovera" v-text="item.contentText"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-column flex-center bgbox2">
      <div class="flex flex-column box1">
        <div class="flex flex-center">
          <img src="../assets/icon1.png" alt="" class="icon1" />
        </div>
        <p class="title">专家团队及合作单位</p>
        <div class="flex flex-center titles pore">
          <p>Expert cooperation unit</p>
        </div>
      </div>
      <div class="contextbox">
        <div class="flex flex-centers cont1">
          <div class="cont2 flex flex-center" style="cursor: pointer" @click="cooperationtype = 1">
            <div>专家团队</div>
            <div v-if="cooperationtype == 1" class="cont2line"></div>
          </div>
          <div class="cont2 flex flex-center" style="cursor: pointer" @click="cooperationtype = 2">
            <div>合作单位</div>
            <div v-if="cooperationtype == 2" class="cont2line"></div>
          </div>
        </div>
        <div class="cont3" v-if="cooperationtype == 1">
          <div class="flex flex-wrap" style="justify-content: center">
            <div class="zj-item">
              <div class="headimg">
                <img src="../assets/pp/周涛.jpg" alt="" />
              </div>
              <div class="name">周涛</div>
              <div class="chengwei">
                教授，博导，电子科技大学大数据研究院院长
              </div>
            </div>
            <div class="zj-item">
              <div class="headimg">
                <img src="../assets/pp/张小松.jpg" alt="" />
              </div>
              <div class="name">张小松</div>
              <div class="chengwei">
                教授，博导，电子科技大学大数据研究院院长
              </div>
            </div>
            <div class="zj-item">
              <div class="headimg">
                <img src="../assets/pp/徐增林.jpg" alt="" />
              </div>
              <div class="name">徐增林</div>
              <div class="chengwei">
                教授，博导，电子科技大学大数据研究中心数据挖掘与推理研究所所长
              </div>
            </div>
            <div class="zj-item">
              <div class="headimg">
                <img src="../assets/pp/王勇.jpg" alt="" />
              </div>
              <div class="name">王勇</div>
              <div class="chengwei">博士，教授，国家特聘专家</div>
            </div>
            <div class="zj-item">
              <div class="headimg">
                <img src="../assets/pp/傅彦.jpg" alt="" />
              </div>
              <div class="name">傅彦</div>
              <div class="chengwei">
                教授，博导，电子科技大学大数据研究中心副主任
              </div>
            </div>

            <div class="zj-item">
              <div class="headimg">
                <img src="../assets/pp/陈雷霆.jpg" alt="" />
              </div>
              <div class="name">陈雷霆</div>
              <div class="chengwei">
                教授，博导，IET会员，中国软件行业协会理事，美国伊利诺伊大学香槟分校访问学者
              </div>
            </div>
            <div class="zj-item">
              <div class="headimg">
                <img src="../assets/pp/邵俊明.jpg" alt="" />
              </div>
              <div class="name">邵俊明</div>
              <div class="chengwei">
                教授，博士生导师，洪堡学者，“校百人计划”入选者
              </div>
            </div>
            <div class="zj-item">
              <div class="headimg">
                <img src="../assets/pp/刘明.jpg" alt="" />
              </div>
              <div class="name">刘明</div>
              <div class="chengwei">教授，博导</div>
            </div>
            <div class="zj-item">
              <div class="headimg">
                <img src="../assets/pp/荣智海.jpg" alt="" />
              </div>
              <div class="name">荣志海</div>
              <div class="chengwei">教授，博士生导师</div>
            </div>

            <!--             
            <img src="../assets/home1.png" class="cont4" />
            <img src="../assets/home2.png" class="cont4" />
            <img src="../assets/home3.png" class="cont4" />
            <img src="../assets/home4.png" class="cont4" />
            <img src="../assets/home5.png" class="cont4" />
            <img src="../assets/home6.png" class="cont4" />
            <img src="../assets/home7.png" class="cont4" />
            <img src="../assets/home8.png" class="cont4" />
            <img src="../assets/home9.png" class="cont4" /> -->
          </div>
        </div>
        <div class="cont5 flex flex-wrap" v-if="cooperationtype == 2">
          <img src="../assets/homes1.png" class="cont6" />
          <img src="../assets/homes2.png" class="cont6" />
          <img src="../assets/homes3.png" class="cont6" />
          <img src="../assets/homes4.png" class="cont6" />
          <img src="../assets/homes5.png" class="cont6" />
          <img src="../assets/homes6.png" class="cont6" />
          <img src="../assets/homes7.png" class="cont6" />
          <img src="../assets/homes8.png" class="cont6" />
          <img src="../assets/homes9.png" class="cont6" />
          <img src="../assets/homes10.png" class="cont6" />
          <img src="../assets/homes11.png" class="cont6" />
          <img v-if="false" src="../assets/homes12.png" class="cont6" />
          <img src="../assets/homes13.png" class="cont6" />
          <img src="../assets/homes14.png" class="cont6" />
          <img src="../assets/homes15.png" class="cont6" />
          <img src="../assets/homes16.png" class="cont6" />
          <img src="../assets/homes17.png" class="cont6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaulttop from "../components/defaulttop";
export default {
  name: "Home",
  components: {
    defaulttop,
  },
  data() {
    return {
      tablist: ["全部", "行业创新", "行业创新", "行业创新"],
      bannerList: [],
      ciarticleListTop4: [],
      countNum: [],
      cooperationlist: [
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
        "1",
      ],
      cooperationtype: 1, //1.专家团队 2.合作伙伴
      itemindex: 0,
      caseList: [],
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: true, // 手动切换之后继续自动轮播
        },
        loop: true,
      },
      swiperOption1: {
        loop: true,
        centeredSlides: true, // 选中slide居中显示
        centeredSlidesBounds: true,
        initialSlide: 4, // 默认选中项索引
        slidesPerView: 1.55, // 自动匹配每次显示的slide个数,loop='auto'模式下，还需要设置loopedSlides
        effect: "coverflow", // 切换效果-3d
        coverflowEffect: {
          rotate: 0,
          stretch: 100,
          depth: 120,
          modifier: 3,
          slideShadows: true,
        },
        on: {
          click: () => {
            // 通过$refs获取对应的swiper对象
            let swiper = this.$refs.swiper1;
            let i = swiper.$swiper.realIndex;
          },
        },
      },
      swiperOption2: {
        loop: true,
        centeredSlides: true, // 选中slide居中显示
        centeredSlidesBounds: true,
        initialSlide: 0, // 默认选中项索引
        slidesPerView: 3, // 自动匹配每次显示的slide个数,loop='auto'模式下，还需要设置loopedSlides
        effect: "coverflow", // 切换效果-3d
        coverflowEffect: {
          rotate: -12,
          stretch: 0,
          depth: 0,
          modifier: 2,
          slideShadows: false,
        },
      },
      swiperOption1active: 4,
      swiperOption2index: 0,
    };
  },
  computed: {
    problemCls() {
      return function (index) {
        let arr = ["flex flex-row flex-centers box2item"];
        arr.push(this.swiperOption1active == index ? "box2itemac" : "");
        return arr;
      };
    },
    swiper2() {
      return;
    },
  },
  mounted() {
    window.$vm = this;
    this.$refs.swiper1.$swiper.slideTo(4, 600, false);
    this.getData();
  },
  methods: {
    toMore2(args) {
      console.log(args);
    },
    // 查看更多
    toMore(path, query) {
      this.$router.push({ path, query });
    },
    // 获取初始化数据
    getData() {
      // 获取banner数据
      this.$http.getBannerList().then((res) => {
        if (res.code == 0) this.bannerList = res.data;
      });

      // 获取中心动态：Top 4
      this.$http.getCenterArticleListTop4().then((res) => {
        if (res.code == 0) {
          let _list = res.data.list;
          this.ciarticleListTop4 = _list.map((item) => {
            let dom_div = document.createElement("div");
            dom_div.innerHTML = item.context;
            item.contentText = dom_div.innerText;
            return item;
          });
        }
      });

      // 获取统计数据
      this.$http.getCountNum().then((res) => {
        if (res.code == 0) this.countNum = res.data;
      });

      // 获取案例数据
      this.$http.arClassList(1, 5, 2, 14).then((res) => {
        // console.log('arClassList',res);
        this.caseList = res.data.list || [];
        console.log(this.caseList);
      });
    },
    // banner链接
    bannerLink(path) {
      // 验证path是否包含host头
      if (/https?/.test(path)) {
        // 验证path是否符合url要求
        if (
          /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/.test(
            path
          )
        ) {
          // 打开新地址
          console.log("Banner地址：", path);
          let a = document.createElement("a");
          a.href = path;
          a.click();
        }
      } else if (
        /[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[.]+[-A-Za-z0-9+&@#/%=~_|]/.test(path)
      ) {
        // 验证path是否符合url要求
        // 打开新地址
        console.log("Banner地址：", path);
        let a = document.createElement("a");
        a.href = path;
        a.click();
      }
    },
    leftswiperOption2() {
      if (this.swiperOption2index == 0) {
        this.swiperOption2index = 5;
      } else {
        this.swiperOption2index -= 1;
      }
      this.$refs.swiperOption2.$swiper.slideTo(
        this.swiperOption2index,
        600,
        false
      );
    },
    rightswiperOption2() {
      if (this.swiperOption2index == 5) {
        this.swiperOption2index = 0;
      } else {
        this.swiperOption2index += 1;
      }
      this.$refs.swiperOption2.$swiper.slideTo(
        this.swiperOption2index,
        600,
        false
      );
    },
    itemclick(i) {
      this.itemindex = i;
    },
    swipernav(i) {
      this.swiperOption1active = i;
      this.$refs.swiper1.$swiper.slideTo(i < 2 ? 5 + i : i, 600, false);
    },
    // 详情
    btndetails(item) {
      this.$router.push({
        path: "/details",
        query: {
          type: 4,
          articleId: item.id,
        },
      });
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.bannerimg {
  width: 100%;
  height: 793px;
}

.swiper-pagination-bullet {
  width: 30px;
  height: 8px;
  display: inline-block;
  border-radius: 50px;
  background: #fff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #ffffff;
  opacity: 1;
}

.icon1 {
  width: 46px;
  height: 46px;
}

.box {
  background-color: #f2f7fc;
  background-image: url(../assets/bg2.png);
  background-repeat: no-repeat;
}

.box1 {
  width: 1164px;
  padding: 30px 0 37px;
}
.box1ss {
  width: 1050px;
  padding: 30px 0 37px;
}
.title {
  font-size: 35px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 15px;
}

.titles {
  font-size: 20px;
  margin-top: 15px;
  font-family: PangMenZhengDao;
  font-weight: 400;
  color: #86949d;
  line-height: 12px;
  opacity: 0.5;
  text-align: center;
}

.info {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #9da3ab;
  line-height: 28px;
  text-align: center;
  margin-top: 48px;
}

.itembox {
  margin-top: 45px;
}

.item {
  color: #333333;
  padding: 15px 20px;
  border-bottom: 1px solid #cddbf7;
  cursor: pointer;
}

.itemac {
  color: #2c6ae5;
  border-bottom: 1px solid #2c6ae5;
}

.iteminfobox {
  background: #ffffff;
  border: 2px solid #eff0f1;
  border-radius: 5px;
  margin-top: 37px;
}

.iteminfo {
  padding: 0 50px;
  height: 155px;
  margin: 35px 0;
  width: 360px;
}

.bdr1 {
  border-right: 1px solid rgba(191, 191, 191, 0.5);
}

.icon2 {
  width: 24px;
  height: 24px;
  margin-right: 27px;
}

.iteminfotitle {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.iteminfotxt {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #9da3ab;
  margin-top: 20px;
  line-height: 26px;
}

.iteminfotxts {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #9da3ab;
  line-height: 26px;
}

.line {
  margin: 0 50px;
  height: 1px;
  background: rgba(191, 191, 191, 0.5);
}

.icon3 {
  width: 24px;
  height: 19px;
  margin-right: 27px;
}

.icon4 {
  width: 146px;
  height: 151px;
  margin-right: 50px;
}

.icon5 {
  width: 158px;
  height: 131px;
  margin-right: 50px;
}

.icon6 {
  width: 171px;
  height: 146px;
  margin-right: 50px;
}

.icon7 {
  width: 158px;
  height: 151px;
  margin-right: 50px;
}

.box2 {
  height: 108px;
  background: url(../assets/bg3.png) no-repeat;
  background-size: 100% 100%;
}

.box2-box {
  width: 1164px;
}

.lines {
  width: 1px;
  height: 55px;
  background: #ffffff;
  opacity: 0.5;
}

.box2title {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #ffffff;
  line-height: 19px;
  opacity: 0.5;
}

.box2num {
  font-size: 37px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.box2lag {
  font-size: 16px;
  font-family: Source Han Sans CN;
  color: #ffffff;
  line-height: 37px;
}

.pore {
  position: relative;
}

.bggradient {
  background: linear-gradient(180deg, #fdfefe 0%, #dee8f9 100%);
}

.fhicon {
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

.more {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c8c8c8;
  line-height: 12px;
}

.box2icon {
  width: 24px;
  height: 24px;
  margin-right: 25px;
}

.box2item {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}

.box2itemac {
  color: #2c6ae5;
}

.mt96 {
  margin-top: 96px;
}

.linea {
  height: 1px;
  background: #d8d9db;
  width: 100%;
}

.swiperOption1item {
  background: linear-gradient(180deg, #ffffff 0%, #f5f8fd 100%);
  box-shadow: 0px 1px 8px 0px rgba(6, 0, 1, 0.05);
  width: 672px;
  height: 302px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #9da3ab;
  padding: 0 70px;
}
.swiperOption1items {
  background: #ffffff;
  height: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #9da3ab;
  padding: 0 30px;
}
.swiperOption1itemtitle {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 32px;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bgbox1 {
  background-color: #f2f7fc;
  background-image: url(../assets/homebg1.png);
  background-repeat: no-repeat;
}
.bgbox2 {
  background-color: #f2f7fc;
  background-image: url(../assets/homebg2.png);
  background-repeat: no-repeat;
}
.contextbox {
  width: 1215px;
}
.context1 {
  padding: 20px 0 40px 0;
}
.txt1 {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.isnew {
  display: none;
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  background: rgba(240, 75, 67, 1);
  border-radius: 10px 10px 10px 0;
  margin-left: 15px;
}
.txt2 {
  font-size: 16px;
  margin-top: 20px;
  font-weight: 400;
  color: #9da3ab;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.times {
  font-size: 16px;
  color: #9da3ab;
  margin-left: 15px;
}
.context2 {
  width: 40px;
  padding-top: 30px;
}
.context2 div {
  width: 5px;
  height: 5px;
  background: #000000;
  border-radius: 50%;
}
.cont1 {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e3e4e6;
}
.cont2 {
  width: 50%;
  height: 100%;
  font-size: 20px;
  font-weight: 300;
  color: #333333;
  position: relative;
}
.cont2line {
  position: absolute;
  width: 52px;
  height: 5px;
  background: #2c6ae5;
  bottom: 0;
}
.cont3 {
  padding: 20px 10px 60px 10px;
}
.cont4 {
  width: 250px;
  height: 162px;
  margin-top: 20px;
  margin-right: 64px;
}
.cont4:nth-child(4n) {
  margin-right: 0;
}
.zj-item {
  width: 151px;
  margin-right: 20px;
  margin-bottom: 18px;
}
.zj-item::after {
  display: table;
  clear: both;
  content: "";
}
.zj-item img {
  width: 100%;
  height: 200px;
}
.zj-item .name {
  font-size: 18px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}
.zj-item .chengwei {
  text-align: center;
  font-size: 12px;
  color: #555;
}

.cont5 {
  padding: 50px 0 100px 0;
}
.cont6 {
  width: 150px;
  height: 94px;
  margin-right: 2px;
  margin-top: 4px;
}
.cont6:nth-child(8n) {
  margin-right: 0;
}
.bg4 {
  background: url(../assets/bg4.png) no-repeat;
  background-size: 100% 100%;
}
.tit10 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #ffffff;
}

.seedetail {
  width: 156px;
  height: 47px;
  background: #2c6ae5;
  border: 1px solid #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #ffffff;
  margin-top: 20px;
}
.lsdkj {
  height: 90px;
  line-height: 28px;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.shujuzhili .swiper-slide-prev {
  transform: none !important;
  transform-style: none !important;
  flex-shrink: 1;
  margin-right: 20px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  box-shadow: 2px 1px 8px 2px rgba(6, 0, 1, 0.1);
}

.el-carousel__item:nth-child(2n + 1) {
  box-shadow: 2px 2px 8px 2px rgba(6, 0, 1, 0.1);
}
</style>
